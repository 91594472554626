import * as React from "react";
import {
  AppBar,
  styled,
  Toolbar,
  Typography,
  Stack,
  Container,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import appTheme from "../style/theme";

// import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
//import MenuItem from '@mui/material/MenuItem';

const pages = ["Home"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
  });

  return (
    <AppBar
      position="sticky"
      color="bar"
      elevation={0}
      sx={{
        mt: 0,
        padding: "0.5rem 0.5rem 0.5rem",
      }}
    >
      <StyledToolbar disableGutters>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <Typography
            variant="h4"
            textAlign="right"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", sm: "flex" },
              fontFamily: "Audiowide",
              fontWeight: 300,
              letterSpacing: ".3rem",
              color: "#00A2FF",
              textDecoration: "none",
            }}
          >
            pgr
          </Typography>
          <Typography
            variant="h7"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", sm: "flex" },
              fontFamily: "Prompt",
              fontWeight: 300,
              letterSpacing: ".3rem",
              color: "#00A2FF",
              textDecoration: "none",
            }}
          >
            Data Solutions GmbH
          </Typography>
        </Stack>

        {/*
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>

            
          </Box>
              */}
      </StyledToolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
