import * as React from "react";

import { Routes, Route } from "react-router-dom";
import { Box, ThemeProvider, CssBaseline } from "@mui/material";

import appTheme from "./style/theme";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Home from "./pages/home/Home";
import Footer from "./components/Footer";

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <Box className="app">
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
