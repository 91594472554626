import { Box, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import StorageIcon from "@mui/icons-material/Storage";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TerminalIcon from "@mui/icons-material/Terminal";
import ApiIcon from "@mui/icons-material/Api";
import TransformIcon from "@mui/icons-material/Transform";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import MoveUpIcon from "@mui/icons-material/MoveUp";

//import { ReactComponent as GoIcon } from '%PUBLIC_URL%/assets/Go-Logo_Blue.svg';

import ExtensionIcon from "@mui/icons-material/Extension";

const Home = () => {
  /*
  const CoverImage = styled("div")({
    width: 100,
    height: 100,
    objectFit: "cover",
    overflow: "hidden",
    flexShrink: 0,
    borderRadius: 8,
    backgroundColor: "rgba(0,0,0,0.08)",
    "& > img": {
      width: "100%",
    },
  });
  */

  const Section = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  }));

  const SectionContent = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    //alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "75.75rem",
    margin: "0 auto",
    padding: "1rem 1.5rem 1rem",
  }));

  const SectionHeaderText = styled(Typography)(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    color: theme.palette.primary.main,
    fontSize: "2em",
    fontWeight: 300,
    justifyContent: "center",

    height: "100px",
  }));

  const GreyCard = styled(Card)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    boxShadow: "4",
    padding: "4",
    borderRadius: "2",
    maxWidth: "345px",
    height: "350px",
    backgroundColor: "#f8f8f8",
    margin: "0.5rem 0.5rem 0.5rem",
  }));

  return (
    <Section flexDirection="column" sx={{ mb: "10px" }}>
      <SectionHeaderText variant="h2">Our Capabilities</SectionHeaderText>

      <SectionContent flexDirection="row">
        <GreyCard>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Service
            </Typography>
            <Typography variant="h5" component="div">
              Consulting
            </Typography>

            <Typography variant="body2" color="primary" mt={1} mb={1}>
              Enjoy best solutions from our consulting services.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      API-Led Integration
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Warehousing
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Integration / ETL
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      System & Data Architecture
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Migration
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </GreyCard>
        <GreyCard>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Integration
            </Typography>
            <Typography variant="h5" component="div">
              API-Led Connectivity
            </Typography>

            <Typography variant="body2" color="primary" mt={1} mb={1}>
              Unlock your data and connect to applcations. Transform your
              business from ETL to API-Led connectivity.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <TerminalIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      GO
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <TerminalIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Node.js
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <ApiIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Mulesoft
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <TransformIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      SAP Data Services
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <TransformIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Azure Data Factory
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </GreyCard>
        <GreyCard>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Cloud
            </Typography>
            <Typography variant="h5" component="div">
              Digital Transformation
            </Typography>

            <Typography variant="body2" color="primary" mt={1} mb={1}>
              Say goodbye to old fashion solutions with cloud based platforms.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CloudDoneIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Cloud Computing
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <HandshakeIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      System Integration
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <AnalyticsIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Lake and Analytics
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <MoveUpIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Migration
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </GreyCard>
        <GreyCard>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Analytics
            </Typography>
            <Typography variant="h5" component="div">
              Microsoft Power BI
            </Typography>

            <Typography variant="body2" color="primary" mt={1} mb={1}>
              Control your company’s data with self service reporting starting
              from only one week.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <ExtensionIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      End-to-End Solution
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <SelfImprovementIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Enable Users for Self-Service
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <DatasetLinkedIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Integration with Azure Data Factory
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <StorageIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Modelling on Azure Data Lake
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <BarChartIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Reports & Dashboards
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </GreyCard>
        <GreyCard>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Saving
            </Typography>
            <Typography variant="h5" component="div">
              Decommission SAP BW/BO
            </Typography>

            <Typography variant="body2" color="primary" mt={1} mb={1}>
              If you are done with paying more but getting less value with SAP
              BW, just say goodbye! We have a solution for you.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <EngineeringIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Reverse Engineering on SAP BO Reports
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <TransformIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Data Integration from SAP S/4HANA
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <BarChartIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Power BI Report Development
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <DeleteSweepIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      Decommission SAP BW/BO Instances
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </GreyCard>
      </SectionContent>

      <SectionHeaderText variant="h2">Our Customers</SectionHeaderText>

      <Section flexDirection="row" sx={{ justifyContent: "space-evenly" }}>
        <Box
          display="flex"
          alignItems="center"
          alignContent="center"
          height="120px"
          width="240px"
          sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: 3,
            p: 4,
            borderRadius: 2,
          }}
        >
          <img
            src="https://www.aspengl.com/wp-content/uploads/2019/02/aspen-logo-global.png"
            alt=""
            width="auto"
            height="45px"
            max-height="100%"
          />
        </Box>
        <Box
          height="120px"
          width="240px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 3,
            p: 4,
            borderRadius: 2,
          }}
        >
          <img
            src="https://www.cci.com.tr/Portals/0/Images/CCI_Logo.png"
            alt=""
            width="auto"
            height="50px"
            max-height="100%"
          />
        </Box>
      </Section>
    </Section>
  );
};

export default Home;
