import * as React from "react";
import { Box, styled, Typography } from "@mui/material";

const Footer = () => {
  const Section = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: "0.5rem 0.5rem 0.5rem",
    color: "white",
  }));

  return (
    <Section flexDirection="column" mt="80px" bgcolor="#00A2FF">
      <Section
        flexDirection="row"
        mt="10px"
        sx={{ justifyContent: "space-evenly" }}
      >
        <Box mt="10px">
          <Typography variant="body2">
            PGR Data Solutions GmbH <br />
            Putzbrunner Str. 71-73 <br />
            81739 Munich, Germany <br />
            Tax Number: 143/170/60263 <br />
          </Typography>
        </Box>
        <Box mt="10px">
          <Typography variant="body2">
            PGR Data Solutions Ltd. Sti. <br />
            Palladium Office Building, No:8/A <br />
            34746 Atasehir, Istanbul, Turkey <br />
            Tax Number: 7291038715
          </Typography>
        </Box>
      </Section>
      <Section justifyContent="center" mt="5px">
        <Typography variant="body2">info@pgrdata.com</Typography>
      </Section>
      <Section justifyContent="center" mt="10px">
        <Typography variant="h8">© All Rights Reserved 2022</Typography>
      </Section>
    </Section>
  );
};

export default Footer;
